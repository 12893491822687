import React from 'react';

export const RotatedBentSurface = (props) => {
    return (
        <svg {...props} viewBox="0 0 262 260" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M183.064 43.3225C180.634 42.9926 178.286 42.3921 176.079 41.4367C170.083 38.8394 166.26 34.2402 162.563 29.7918C158.028 24.3333 153.744 19.1785 145.735 17.3829C134.423 14.8459 122.396 13.5575 110.767 12.3074C105.017 11.6911 99.0709 11.0527 93.3383 10.2747L32.409 2.00653L32.5622 0.877205L93.4915 9.14543C99.2087 9.92126 105.146 10.5585 110.888 11.1748C122.552 12.4263 134.61 13.72 145.985 16.2711C154.372 18.1506 158.983 23.6991 163.44 29.0628C167.051 33.4086 170.785 37.9014 176.531 40.3906C186.042 44.5082 197.979 41.8432 208.511 39.4942C210.001 39.1617 211.45 38.8383 212.845 38.5458C215.534 37.9819 218.211 37.3894 220.884 36.7985C233.284 34.0548 246.107 31.2169 259.253 31.643L259.215 32.7812C246.213 32.3613 233.462 35.1831 221.129 37.9111C218.453 38.5038 215.772 39.0968 213.079 39.6603C211.688 39.9523 210.244 40.2752 208.759 40.6061C200.867 42.3677 191.477 44.463 183.064 43.3225Z" fill="white"/>
            <path d="M184.552 55.0951C182.171 54.772 179.842 54.2269 177.6 53.3903C171.587 51.1479 166.86 47.1908 162.289 43.3639C156.65 38.6429 151.324 34.1834 143.686 32.4977C140.441 31.7823 137.179 31.0498 133.904 30.3144C119.958 27.1842 105.536 23.9479 91.4381 21.7663C80.9558 20.1507 70.1927 19.7032 59.7925 19.2791C50.2703 18.8899 40.4264 18.4886 30.7742 17.1788C30.6341 17.1597 30.4953 17.1398 30.3564 17.1209L30.5118 15.9919C40.2429 17.3079 50.2055 17.7456 59.8392 18.1398C70.2695 18.5657 81.0408 19.0031 91.6134 20.64C105.748 22.8276 120.189 26.0688 134.154 29.2027C137.428 29.9379 140.689 30.6702 143.931 31.3854C151.842 33.1305 157.525 37.889 163.021 42.4905C167.518 46.2558 172.168 50.1486 177.999 52.3235C187.595 55.8967 199.004 53.9632 209.075 52.2568C210.454 52.0228 211.812 51.7926 213.143 51.5801C215.386 51.2207 217.622 50.8491 219.854 50.4781C231.97 48.4619 244.499 46.377 257.199 46.7572L257.166 47.8961C244.575 47.5195 232.659 49.5022 220.041 51.602C217.808 51.9739 215.569 52.3463 213.322 52.7052C211.995 52.9171 210.641 53.1477 209.265 53.3799C201.37 54.7189 192.661 56.1956 184.552 55.0951Z" fill="white"/>
            <path d="M190.341 63.6317C187.77 63.2829 185.219 62.8131 182.691 62.1948C175.827 60.5165 169.329 57.7635 163.044 55.1011C156.217 52.2104 149.16 49.2208 141.638 47.6161C132.529 45.6713 123.897 42.0279 115.549 38.5037C107.248 34.9996 98.6633 31.376 89.6876 29.4762C78.3174 27.0628 66.6539 28.7226 55.3637 30.3208C46.5199 31.5714 37.3747 32.8654 28.3437 32.2419L28.4217 31.1046C37.3336 31.7198 46.4181 30.4344 55.2036 29.1916C66.592 27.581 78.3712 25.9212 89.9244 28.3605C99.0059 30.2836 107.641 33.9287 115.992 37.4542C124.291 40.957 132.872 44.5801 141.877 46.5006C149.504 48.1287 156.613 51.1398 163.488 54.0518C169.731 56.6971 176.188 59.431 182.962 61.0883C196.506 64.3964 210.863 63.3896 224.744 62.4104C234.785 61.7029 245.168 60.9723 255.183 61.8765L255.08 63.0105C245.156 62.1153 234.82 62.8432 224.825 63.5479C213.429 64.35 201.716 65.1753 190.341 63.6317Z" fill="white"/>
            <path d="M189.993 71.825C189.082 71.7014 188.174 71.5737 187.265 71.4403C172.646 69.2959 156.605 66.3665 139.586 62.7328C129.541 60.5879 120.887 55.1511 112.518 49.8946C104.822 45.0596 96.8636 40.0609 87.9181 37.7238C76.3617 34.7123 64.5325 38.3792 53.0894 41.9322C44.3525 44.6462 35.3178 47.4512 26.3259 47.3598L26.3368 46.2201C35.1504 46.3098 44.0983 43.5312 52.7519 40.8441C64.3473 37.2444 76.3368 33.5217 88.2069 36.6218C97.3218 39.0021 105.355 44.0491 113.124 48.93C121.409 54.1336 129.976 59.5159 139.825 61.6173C156.819 65.2465 172.836 68.1715 187.432 70.3128C198.275 71.9078 209.38 72.7801 220.115 73.6282C230.967 74.4852 242.192 75.374 253.163 76.9964L252.997 78.124C242.056 76.5079 230.859 75.6207 220.025 74.7649C210.168 73.9867 200.001 73.1832 189.993 71.825Z" fill="white"/>
            <path d="M172.87 83.8534C160.982 82.2401 149.131 80.3298 137.535 77.8509C127.25 75.651 118.681 69.744 110.393 64.0312C102.821 58.8097 94.9903 53.4117 85.9466 50.9625C74.3484 47.8116 63.2896 51.8436 51.59 56.1032C42.803 59.3023 33.718 62.6104 24.4719 62.5047L24.485 61.3653C33.5227 61.4675 42.5094 58.1966 51.1997 55.0315C62.5519 50.8989 74.2912 46.6279 86.2442 49.8617C95.4764 52.3624 103.389 57.8176 111.041 63.0925C119.236 68.7422 127.71 74.5847 137.774 76.7355C161.079 81.7231 185.467 84.4037 209.054 86.9969C222.824 88.5128 237.056 90.0803 250.875 92.0824L250.711 93.2102C236.917 91.2092 222.689 89.6434 208.93 88.1301C197.036 86.8216 184.934 85.4905 172.87 83.8534Z" fill="white"/>
            <path d="M189.494 104.503C188.346 104.348 187.2 104.175 186.056 103.986C176.271 102.364 166.423 100.006 156.898 97.7236C149.892 96.045 142.647 94.3094 135.496 92.8852C125.684 90.9304 116.86 86.1916 108.326 81.609C100.123 77.2038 91.6386 72.6482 82.3696 70.6503C71.9558 68.406 61.4411 71.2258 51.2768 73.955C43.1361 76.14 34.718 78.3997 26.244 78.1415L26.2794 77.003C34.5845 77.2562 42.92 75.0178 50.9815 72.8535C61.2764 70.0892 71.9211 67.2219 82.6105 69.5373C92.0356 71.5688 100.592 76.1623 108.865 80.6051C117.325 85.1484 126.074 89.8466 135.719 91.7677C142.89 93.1957 150.147 94.9341 157.164 96.6153C166.668 98.8922 176.498 101.247 186.242 102.861C196.61 104.581 207.219 104.82 217.477 105.05C226.253 105.247 235.33 105.452 244.251 106.579L244.109 107.71C235.245 106.59 226.199 106.387 217.451 106.189C208.281 105.983 198.831 105.77 189.494 104.503Z" fill="white"/>
            <path d="M186.751 125.636C185.045 125.405 183.333 125.075 181.612 124.624C174.279 122.708 167.203 119.789 160.359 116.965C151.756 113.416 142.86 109.745 133.453 108.086C124.359 106.483 115.491 103.634 106.916 100.88C98.6232 98.2167 90.0486 95.4628 81.3206 93.8572C71.9751 92.1408 62.601 92.896 53.5279 93.6258C45.931 94.237 38.0752 94.8692 30.2018 94.0748L30.3153 92.9401C38.0868 93.724 45.8902 93.0971 53.436 92.4902C62.583 91.7536 72.04 90.9928 81.5264 92.7372C90.327 94.3549 98.937 97.1205 107.265 99.7949C115.803 102.537 124.633 105.373 133.65 106.963C143.18 108.645 152.134 112.338 160.794 115.912C167.602 118.72 174.643 121.625 181.901 123.522C192.305 126.239 202.056 124.574 212.38 122.81C219.684 121.561 227.235 120.271 234.967 120.714L234.903 121.851C227.299 121.417 219.813 122.695 212.572 123.933C204.198 125.363 195.584 126.835 186.751 125.636Z" fill="white"/>
            <path d="M184.185 145.419C182.442 145.183 180.646 144.78 178.776 144.178C171.565 141.854 164.836 138.102 158.327 134.472C149.888 129.766 141.162 124.901 131.403 123.203C112.672 119.944 93.5199 117.614 74.9989 115.364C61.1665 113.68 46.8622 111.94 32.8419 109.828L33.0128 108.702C47.0109 110.811 61.3121 112.55 75.1367 114.233C93.6711 116.484 112.838 118.816 131.598 122.08C141.548 123.811 150.359 128.725 158.882 133.477C165.342 137.08 172.022 140.804 179.126 143.093C189.578 146.456 197.842 143.308 206.591 139.971C212.98 137.533 219.587 135.012 227.273 135.068L227.264 136.208C219.793 136.153 213.287 138.635 206.998 141.036C199.599 143.859 192.54 146.552 184.185 145.419Z" fill="white"/>
            <path d="M181.494 160.123C179.723 159.882 177.903 159.476 176.021 158.87C168.935 156.588 162.312 152.92 155.908 149.374C147.58 144.762 138.969 139.994 129.351 138.321C112.154 135.33 94.3474 132.259 76.53 129.864C69.6502 128.948 62.6212 128.321 55.8243 127.713C47.6034 126.981 39.1019 126.221 30.7894 124.946L30.9625 123.819C39.2398 125.089 47.7202 125.846 55.9254 126.579C62.7347 127.186 69.7759 127.815 76.681 128.734C94.4912 131.108 112.337 134.204 129.547 137.198C139.354 138.904 148.05 143.719 156.46 148.377C162.817 151.897 169.39 155.538 176.371 157.785C186.822 161.143 195.393 158.096 204.473 154.866C210.951 152.559 217.648 150.175 225.218 150.186L225.216 151.325C217.844 151.315 211.241 153.666 204.855 155.94C197.202 158.664 189.898 161.263 181.494 160.123Z" fill="white"/>
            <path d="M176.986 168.369C174.953 168.094 172.927 167.72 170.909 167.226C164.258 165.596 157.793 163.177 151.541 160.837C143.757 157.925 135.71 154.913 127.299 153.438C117.531 151.724 108.086 148.465 98.9508 145.315C90.291 142.328 81.3374 139.24 72.1699 137.495C63.2506 135.808 54.5764 136.992 46.1794 138.145C39.0388 139.126 31.6556 140.141 24.0509 139.427L24.1579 138.291C31.6305 138.993 38.9484 137.988 46.0247 137.016C54.5113 135.85 63.286 134.637 72.3824 136.376C81.6321 138.135 90.6244 141.237 99.3217 144.236C108.415 147.373 117.817 150.617 127.496 152.314C136.011 153.809 144.109 156.84 151.94 159.77C158.162 162.098 164.594 164.505 171.181 166.119C182.331 168.853 193.9 167.851 205.086 166.886C212.869 166.212 220.916 165.517 228.82 166.065L228.742 167.203C220.926 166.661 212.923 167.352 205.183 168.021C195.913 168.822 186.383 169.644 176.986 168.369Z" fill="white"/>
            <path d="M155.697 173.236C145.471 171.848 135.243 170.293 125.17 168.423C113.993 166.349 104.022 160.714 94.3804 155.264C85.3563 150.162 76.0241 144.888 65.7886 142.613C56.1396 140.469 47.6301 143.998 38.611 147.732C31.4411 150.7 24.0285 153.769 16.0017 153.724L16.0082 152.584C23.8049 152.628 31.1103 149.604 38.1744 146.679C46.9603 143.041 56.0558 139.274 66.0361 141.501C76.4375 143.812 85.8445 149.13 94.9407 154.272C104.501 159.676 114.387 165.263 125.378 167.303C145.753 171.081 166.79 173.585 187.135 176.002C202.544 177.833 218.479 179.726 234.012 182.163L233.834 183.289C218.323 180.856 202.4 178.964 187 177.134C176.687 175.908 166.194 174.661 155.697 173.236Z" fill="white"/>
            <path d="M125.272 184.002C124.576 183.908 123.883 183.797 123.19 183.667C111.119 181.409 101.003 174.255 91.2191 167.336C82.5257 161.188 73.5349 154.83 63.2658 152.016C52.2738 148.992 43.4457 154.083 34.1069 159.469C26.7081 163.735 19.0595 168.146 10.1305 168.319L10.1077 167.179C18.7439 167.012 26.2646 162.675 33.5377 158.482C42.6668 153.218 52.109 147.774 63.5679 150.916C74.0295 153.784 83.1032 160.201 91.8777 166.406C101.561 173.254 111.574 180.334 123.4 182.548C130.442 183.866 137.786 183.219 144.88 182.593C150.006 182.14 155.307 181.671 160.485 181.944C176.251 182.767 192.095 186.965 207.419 191.019C216.976 193.548 226.86 196.164 236.534 197.899L236.332 199.022C226.613 197.277 216.707 194.656 207.127 192.12C191.861 188.081 176.075 183.9 160.425 183.081C155.327 182.814 150.067 183.279 144.98 183.728C138.5 184.301 131.82 184.891 125.272 184.002Z" fill="white"/>
            <path d="M122.996 199.085C122.377 199.001 121.758 198.901 121.137 198.783C109.214 196.522 99.23 189.522 89.5764 182.752C80.9492 176.703 72.0287 170.449 61.816 167.683C50.7937 164.692 41.7826 169.641 32.2422 174.874C24.6808 179.022 16.8611 183.312 7.87498 183.409L7.86351 182.268C16.5638 182.175 24.255 177.956 31.6937 173.875C41.0112 168.762 50.6397 163.476 62.1136 166.582C72.519 169.401 81.5225 175.714 90.2297 181.82C99.7862 188.519 109.668 195.448 121.35 197.664C128.654 199.037 135.955 197.913 143.01 196.813C148.379 195.975 153.93 195.108 159.522 195.33C174.359 195.921 188.918 200.438 202.998 204.808C213.342 208.018 224.038 211.338 234.708 213.046L234.528 214.172C223.778 212.45 213.043 209.119 202.66 205.897C188.649 201.548 174.162 197.052 159.477 196.47C153.996 196.252 148.5 197.11 143.185 197.938C136.626 198.962 129.862 200.017 122.996 199.085Z" fill="white"/>
            <path d="M119.8 214.006C119.568 213.975 119.335 213.941 119.103 213.905C107.583 212.081 97.798 205.596 88.3347 199.327C80.1023 193.87 71.5882 188.229 62.0171 185.742C50.9741 182.887 41.3614 187.081 31.1811 191.533C23.1619 195.041 14.8707 198.666 5.80894 198.526L5.82645 197.387C14.6406 197.523 22.8173 193.947 30.7241 190.489C40.6343 186.156 50.8907 181.673 62.3037 184.64C72.0593 187.174 80.6537 192.869 88.9645 198.377C98.3266 204.58 108.006 210.993 119.281 212.779C127.46 214.033 135.264 211.913 142.834 209.827C148.791 208.185 154.951 206.489 161.322 206.421C175.897 206.255 189.944 212.23 203.524 217.998C213.031 222.035 222.86 226.21 232.678 228.167L232.454 229.287C222.523 227.306 212.638 223.107 203.078 219.047C189.608 213.324 175.681 207.413 161.334 207.559C155.111 207.627 149.024 209.303 143.137 210.926C135.663 212.985 127.942 215.111 119.8 214.006Z" fill="white"/>
            <path d="M116.668 228.972C105.791 227.496 96.3424 221.67 87.2011 216.033C79.3811 211.211 71.2951 206.223 62.3965 204.037C51.2539 201.287 41.0639 204.693 30.2753 208.29C21.7712 211.125 12.9775 214.059 3.74086 213.643L3.79205 212.504C12.8176 212.912 21.509 210.012 29.9153 207.21C40.3817 203.72 51.2088 200.113 62.6683 202.928C71.7403 205.161 79.9042 210.195 87.7999 215.063C96.9495 220.705 106.434 226.604 117.212 227.893C126.311 229.034 134.763 225.81 142.937 222.691C149.489 220.193 156.262 217.608 163.423 217.202C177.696 216.381 190.963 223.844 203.794 231.051C212.521 235.955 221.547 241.025 230.653 243.292L230.377 244.396C221.123 242.093 212.029 236.984 203.235 232.044C190.549 224.92 177.428 217.551 163.489 218.339C156.503 218.737 149.814 221.288 143.344 223.757C135.039 226.925 126.451 230.201 117.07 229.024C116.936 229.008 116.801 228.99 116.668 228.972Z" fill="white"/>
            <path d="M114.509 244.072C103.984 242.644 94.7142 237.201 85.7448 231.936C78.081 227.437 70.1563 222.783 61.5327 220.705C50.3028 217.987 39.1831 221.143 28.4354 224.182C19.7818 226.629 10.8339 229.159 1.69072 228.758L1.73971 227.619C10.7008 228.013 19.5587 225.509 28.1247 223.086C39.0128 220.006 50.2687 216.823 61.8001 219.596C70.5878 221.714 78.5863 226.412 86.322 230.952C95.3378 236.248 104.644 241.786 115.16 243.008C124.766 244.189 133.51 240.39 141.966 236.717C148.8 233.747 155.869 230.677 163.458 230.145C177.615 229.157 189.984 237.153 201.942 244.888C210.468 250.405 219.285 256.107 228.601 258.405L228.327 259.513C218.826 257.168 209.928 251.412 201.322 245.846C189.523 238.211 177.327 230.309 163.537 231.284C156.144 231.799 149.167 234.83 142.42 237.763C133.824 241.496 124.936 245.357 115.02 244.14C114.849 244.119 114.678 244.095 114.509 244.072Z" fill="white"/>
            <path d="M229.029 259.035L227.899 258.882C228.741 252.682 229.849 246.221 230.92 239.973C233.305 226.062 235.772 211.679 235.766 197.977C235.761 189.425 232.902 181.082 230.137 173.013C226.841 163.393 223.434 153.446 224.779 143.001C226.125 132.551 232.163 123.826 238.003 115.389C242.354 109.103 246.852 102.603 249.32 95.4833C253.944 82.1443 255.332 66.496 256.675 51.3629C257.273 44.6286 257.837 38.2684 258.669 32.1343L259.799 32.2875C258.97 38.3951 258.406 44.7431 257.811 51.4642C256.461 66.665 255.067 82.3843 250.397 95.8564C247.878 103.124 243.335 109.689 238.941 116.037C233.178 124.363 227.22 132.972 225.909 143.147C224.597 153.327 227.962 163.148 231.215 172.644C234.01 180.8 236.9 189.235 236.904 197.976C236.912 211.775 234.437 226.209 232.043 240.166C230.974 246.403 229.867 252.855 229.029 259.035Z" fill="white"/>
            <path d="M213.655 253.399L212.526 253.245C214.906 235.843 217.452 215.407 218.297 195.789C218.64 187.84 216.952 179.863 215.321 172.151C213.402 163.082 211.419 153.705 212.691 144.258C214.123 133.619 219.324 124.01 224.355 114.719C228.259 107.506 232.299 100.046 234.51 92.1931C237.705 80.8459 238.085 68.6516 238.453 56.8601C238.695 49.0589 238.947 40.994 240.012 33.2504L241.141 33.4059C240.085 41.0888 239.834 49.1247 239.592 56.8955C239.222 68.7554 238.841 81.0202 235.607 92.5015C233.361 100.478 229.292 107.993 225.357 115.262C220.375 124.463 215.225 133.978 213.82 144.409C212.575 153.662 214.537 162.941 216.436 171.914C218.082 179.698 219.784 187.745 219.436 195.838C218.59 215.503 216.039 235.972 213.655 253.399Z" fill="white"/>
            <path d="M196.858 243.557C197.683 235.139 197.598 226.596 197.517 218.334C197.423 208.967 197.328 199.282 198.552 189.71C199.209 184.567 199.793 179.32 200.358 174.247C201.279 165.969 202.232 157.408 203.534 149.04C205.243 138.051 208.747 127.375 212.136 117.05C214.789 108.97 217.531 100.615 219.388 92.1804C221.696 81.6944 220.283 71.4728 218.915 61.5876C217.863 53.9678 216.773 46.0881 217.332 38.0008L218.469 38.0787C217.918 46.0493 218.999 53.8693 220.045 61.432C221.425 71.4155 222.852 81.7401 220.5 92.4257C218.633 100.916 215.88 109.299 213.219 117.406C209.844 127.688 206.354 138.319 204.66 149.215C203.363 157.559 202.41 166.107 201.491 174.373C200.925 179.451 200.341 184.703 199.682 189.854C198.468 199.348 198.564 208.995 198.656 218.323C198.738 226.614 198.823 235.191 197.993 243.666L196.858 243.557Z" fill="white"/>
            <path d="M181.232 234.448C181.634 227.755 180.566 220.759 179.532 213.994C178.062 204.372 176.543 194.424 179.144 184.966C180.663 179.437 183.59 174.688 186.42 170.095C189.574 164.978 192.835 159.686 194.076 153.359C197.904 133.82 201.431 112.78 204.558 90.8252C205.747 82.4709 206.366 75.4059 202.711 66.9209C201.943 65.1387 201.192 63.5103 200.479 61.967C197.551 55.6292 195.238 50.6228 195.228 42.2381L196.367 42.2377C196.377 50.3719 198.644 55.2777 201.513 61.4896C202.23 63.0414 202.986 64.6782 203.759 66.47C207.524 75.2127 206.859 82.7453 205.686 90.9851C202.557 112.96 199.027 134.02 195.194 153.578C193.914 160.107 190.598 165.489 187.39 170.693C184.602 175.217 181.718 179.896 180.241 185.268C177.706 194.492 179.207 204.319 180.659 213.823C181.701 220.645 182.779 227.701 182.371 234.517L181.232 234.448Z" fill="white"/>
            <path d="M165.983 230.533C166.369 223.743 165.066 216.863 163.806 210.211C162.164 201.549 160.467 192.591 162.473 183.701C163.918 177.299 167.794 172.819 171.54 168.486C175.371 164.057 179.332 159.478 180.641 152.827C183.528 138.152 185.317 122.884 187.048 108.119C187.862 101.167 188.704 93.9778 189.648 86.9726C190.673 79.3619 191.16 72.9799 187.409 65.9296C186.312 63.8669 185.266 62.1076 184.253 60.4056C180.944 54.8427 178.33 50.4491 178.268 41.8385L179.408 41.8314C179.467 50.1328 181.885 54.1966 185.232 59.823C186.252 61.536 187.306 63.3076 188.416 65.3946C192.325 72.7449 191.831 79.3078 190.778 87.1248C189.835 94.1211 188.994 101.305 188.179 108.253C186.448 123.039 184.656 138.329 181.759 153.046C180.392 159.995 176.33 164.691 172.402 169.232C168.579 173.653 164.967 177.829 163.585 183.953C161.631 192.61 163.306 201.451 164.926 209.999C166.199 216.72 167.515 223.667 167.12 230.597L165.983 230.533Z" fill="white"/>
            <path d="M151.228 233.51C151.763 226.468 151.068 219.161 150.397 212.097C149.494 202.598 148.559 192.774 150.569 183.351C151.971 176.782 155.166 171.067 158.256 165.541C161.485 159.766 164.823 153.794 166.063 146.884C168.918 130.987 170.912 114.563 172.84 98.6801C173.404 94.0415 173.966 89.4095 174.549 84.7938C175.622 76.2989 175.781 69.3963 173.27 61.2406C172.975 60.2795 172.68 59.3387 172.39 58.4132C169.97 50.682 167.879 44.0053 168.129 35.3144L169.268 35.3477C169.024 43.8473 171.088 50.4395 173.478 58.0733C173.768 59.0001 174.063 59.942 174.36 60.9055C176.922 69.2288 176.74 76.5316 175.679 84.936C175.097 89.5507 174.535 94.1804 173.972 98.8179C172.041 114.716 170.045 131.156 167.186 147.086C165.912 154.184 162.526 160.24 159.251 166.097C156.202 171.549 153.05 177.188 151.685 183.589C149.71 192.841 150.636 202.575 151.532 211.989C152.207 219.102 152.906 226.457 152.364 233.597L151.228 233.51Z" fill="white"/>
            <path d="M136.684 239.385C137.68 231.11 137.975 222.625 138.26 214.422C138.622 204.035 138.996 193.295 140.793 182.839C142.036 175.609 144.142 168.514 146.178 161.653C148.374 154.257 150.643 146.611 151.824 138.808C153.65 126.74 155.257 114.423 156.812 102.512C157.63 96.2431 158.448 89.9746 159.3 83.714C160.673 73.6268 160.456 63.4653 160.247 53.6373C160.057 44.6756 159.859 35.4074 160.863 26.1966L161.996 26.3212C161 35.4577 161.196 44.6876 161.386 53.6134C161.597 63.4853 161.815 73.6918 160.429 83.8672C159.578 90.1256 158.76 96.3919 157.942 102.659C156.387 114.576 154.778 126.899 152.95 138.979C151.759 146.859 149.477 154.545 147.271 161.978C145.245 168.807 143.147 175.869 141.917 183.032C140.132 193.412 139.759 204.113 139.4 214.462C139.113 222.689 138.817 231.197 137.815 239.52L136.684 239.385Z" fill="white"/>
            <path d="M123.141 243.687L122.013 243.525C127.005 208.708 131.48 173.195 135.808 138.851C140.78 99.3994 145.921 58.6051 151.873 18.6455L153.001 18.8142C147.051 58.7608 141.91 99.5482 136.939 138.994C132.61 173.343 128.134 208.859 123.141 243.687Z" fill="white"/>
            <path d="M108.047 241.82L106.919 241.658C116.322 175.313 125.386 107.767 134.15 42.4452L137.737 15.7245L138.867 15.8755L135.28 42.5974C126.515 107.922 117.451 175.47 108.047 241.82Z" fill="white"/>
            <path d="M91.4975 234.561C92.6543 225.283 93.2819 215.783 93.8897 206.595C94.6458 195.18 95.4265 183.377 97.2256 171.874C98.3846 164.467 100.296 157.133 102.146 150.039C104.094 142.565 106.109 134.837 107.235 127.039C110.443 104.841 113.442 82.218 116.343 60.3393C118.372 45.0375 120.47 29.2138 122.621 13.6714L123.75 13.8268C121.599 29.3671 119.502 45.1886 117.472 60.4892C114.571 82.3712 111.572 104.998 108.363 127.201C107.228 135.063 105.205 142.822 103.248 150.327C101.407 157.392 99.502 164.699 98.3519 172.049C96.5595 183.503 95.7812 195.28 95.0273 206.671C94.4185 215.875 93.7885 225.392 92.6286 234.701L91.4975 234.561Z" fill="white"/>
            <path d="M75.9907 226.047C76.8405 217.26 76.7645 208.029 76.6909 199.103C76.5796 185.72 76.4653 171.88 79.4218 158.932C80.7265 153.216 83.2026 147.989 85.5959 142.933C88.2691 137.288 91.0335 131.451 92.1209 124.977C94.344 111.748 95.6777 98.0962 96.9682 84.8944C97.737 77.0277 98.5308 68.8937 99.5052 60.9436C100.668 51.4489 102.357 41.9033 103.992 32.6715C105.211 25.7824 106.473 18.6598 107.504 11.6149L108.632 11.7792C107.597 18.8416 106.335 25.9731 105.114 32.8698C103.483 42.0875 101.796 51.6178 100.637 61.0814C99.664 69.0182 98.87 77.1455 98.1023 85.0045C96.8101 98.2263 95.4738 111.898 93.2454 125.166C92.1311 131.796 89.3332 137.706 86.6259 143.421C84.2582 148.421 81.81 153.591 80.533 159.186C77.6064 172.004 77.7199 185.776 77.8299 199.094C77.9041 208.049 77.9806 217.309 77.126 226.156L75.9907 226.047Z" fill="white"/>
            <path d="M60.6276 219.892C61.6577 208.089 61.3702 196.658 61.0659 184.558L60.9964 181.767C60.9561 180.094 60.8918 178.375 60.8259 176.626C60.4981 167.868 60.1254 157.943 62.7227 149.538C64.2264 144.673 66.9702 140.957 69.6232 137.365C72.8594 132.983 75.9157 128.844 76.9763 122.733C79.4694 108.383 80.8086 93.5326 82.1037 79.1713C82.9003 70.3469 83.7228 61.2228 84.8042 52.3191C85.7818 44.2706 87.3831 36.245 88.9306 28.4828C90.1631 22.3053 91.4378 15.9168 92.3878 9.5617L93.515 9.7304C92.5603 16.1118 91.2837 22.5147 90.0481 28.7064C88.5047 36.4467 86.9075 44.4503 85.9356 52.4569C84.8556 61.3428 84.0342 70.4581 83.2388 79.2737C81.9416 93.6583 80.6005 108.532 78.1 122.928C76.9921 129.304 73.7117 133.746 70.5399 138.042C67.9432 141.559 65.2575 145.194 63.8114 149.874C61.2719 158.094 61.6394 167.916 61.965 176.583C62.0302 178.337 62.095 180.061 62.1367 181.74L62.2052 184.529C62.4968 196.128 62.7985 208.124 61.7619 219.993L60.6276 219.892Z" fill="white"/>
            <path d="M45.602 219.363C46.3624 210.755 45.9736 201.445 45.5985 192.441C45.0124 178.418 44.4081 163.918 47.9628 150.941C49.4756 145.421 52.5849 141.081 55.5919 136.883C59.07 132.027 62.3544 127.439 63.5084 121.087C65.8854 107.982 66.63 94.3517 67.3496 81.1691C67.8406 72.1797 68.3483 62.8848 69.3689 53.8403C70.3882 44.8147 72.1214 35.7917 73.7963 27.0663C75.0242 20.6748 76.2927 14.0664 77.2711 7.51072L78.3983 7.67941C77.4169 14.2571 76.1455 20.8786 74.9161 27.2811C73.2441 35.9856 71.5151 44.9856 70.5017 53.9682C69.4844 62.9794 68.9778 72.2576 68.4879 81.2315C67.7658 94.4485 67.0196 108.116 64.6287 121.291C63.4304 127.894 59.9163 132.801 56.5184 137.545C53.5696 141.663 50.5211 145.919 49.0619 151.243C45.5541 164.048 46.1549 178.458 46.7371 192.393C47.1141 201.434 47.5044 210.782 46.7363 219.463L45.602 219.363Z" fill="white"/>
            <path d="M30.8322 223.065C31.7674 214.584 31.924 205.707 32.0749 197.122C32.3004 184.344 32.5335 171.13 35.3145 158.707C36.9033 151.609 40.7286 145.667 44.4275 139.921C48.387 133.769 52.4815 127.408 53.7303 119.687C55.612 108.064 55.0648 95.9899 54.5366 84.3137C54.1286 75.3023 53.7072 65.9846 54.3995 56.9102C55.2343 45.966 57.1774 34.9329 59.0554 24.2639C60.141 18.1013 61.2628 11.7302 62.1547 5.46378L63.2831 5.62365C62.3887 11.9088 61.2644 18.2897 60.1787 24.4612C58.3043 35.1038 56.3662 46.1083 55.5356 56.997C54.8483 66.0013 55.2687 75.2852 55.6756 84.2628C56.2061 95.9965 56.7554 108.129 54.8557 119.869C53.5683 127.825 49.4082 134.288 45.385 140.537C41.7402 146.2 37.9714 152.056 36.4262 158.957C33.6707 171.267 33.439 184.421 33.2144 197.142C33.0636 205.752 32.9067 214.655 31.9651 223.191L30.8322 223.065Z" fill="white"/>
            <path d="M17.2215 227.396L16.0922 227.242C17.1268 219.676 17.717 211.743 18.2872 204.073C19.264 190.941 20.2742 177.363 23.5372 164.723C25.532 156.993 29.6773 150.116 33.6853 143.467C38.3368 135.749 43.1465 127.768 44.4813 118.527C45.9959 108.045 44.0221 97.2135 42.1124 86.7381C40.724 79.1166 39.2878 71.2348 39.2031 63.5408C39.0549 50.0327 41.5437 35.9468 43.9515 22.325C45.0557 16.0751 46.198 9.61126 47.0375 3.41639L48.1668 3.56964C47.3241 9.78767 46.1804 16.2625 45.0736 22.5233C42.6749 36.0947 40.1956 50.1268 40.3435 63.5294C40.4267 71.1265 41.8539 78.9588 43.2341 86.5331C45.1598 97.0983 47.15 108.024 45.6095 118.689C44.2423 128.16 39.3708 136.24 34.6612 144.055C30.6953 150.635 26.5947 157.438 24.6397 165.008C21.4025 177.549 20.3968 191.077 19.4236 204.158C18.8521 211.846 18.2607 219.796 17.2215 227.396Z" fill="white"/>
            <path d="M2.2798 228.265L1.15048 228.112C1.99785 221.868 2.68383 215.348 3.34851 209.043C4.82013 195.061 6.34191 180.603 9.76485 167.165C11.8844 158.845 16.2985 151.322 20.5677 144.046C25.5425 135.568 30.6856 126.802 32.0938 116.847C33.5021 106.891 30.813 97.0356 28.2135 87.5043C26.2275 80.2229 24.1741 72.6931 23.9209 65.1067C23.4425 50.7214 26.1545 35.3966 28.7777 20.5764C29.9583 13.9045 31.0741 7.60219 31.9206 1.36445L33.0499 1.5177C32.2004 7.77749 31.0831 14.0908 29.8998 20.7748C27.2876 35.538 24.586 50.8036 25.0606 65.0681C25.3081 72.5224 27.3435 79.9858 29.3134 87.2043C31.9437 96.8476 34.6632 106.819 33.2223 117.007C31.7827 127.185 26.5813 136.049 21.5508 144.623C17.3212 151.83 12.9482 159.283 10.8699 167.447C7.467 180.804 5.94855 195.221 4.48122 209.163C3.81645 215.477 3.12956 222.003 2.2798 228.265Z" fill="white"/>
        </svg>

    )
}