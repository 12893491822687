export const DotsCircle =  (props) => {
    return (
        <svg {...props} viewBox="0 0 72 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M36.6717 69.9074C36.6346 69.2866 37.1127 68.7557 37.7242 68.7257H37.7315C38.3438 68.6885 38.8737 69.1669 38.9109 69.7877C38.9407 70.4012 38.4706 70.9321 37.8511 70.9694C37.8292 70.9694 37.8066 70.9694 37.7913 70.9694C37.1944 70.9694 36.7016 70.5056 36.6717 69.9074Z" fill="white"/>
                <path d="M30.2007 70.5655C29.5892 70.461 29.1781 69.8848 29.2758 69.2793C29.3727 68.6658 29.9471 68.2473 30.5593 68.3445C31.1716 68.4489 31.582 69.0244 31.485 69.6379C31.3954 70.1842 30.9172 70.5801 30.38 70.5801C30.3203 70.5801 30.2605 70.5728 30.2007 70.5655Z" fill="white"/>
                <path d="M44.0313 68.9427C43.8673 68.3445 44.2178 67.731 44.8075 67.5667C45.4045 67.3943 46.024 67.7456 46.188 68.3445C46.3527 68.9427 46.0014 69.5561 45.4118 69.7278C45.3075 69.7504 45.2106 69.765 45.1063 69.765C44.6209 69.765 44.1654 69.4364 44.0313 68.9427Z" fill="white"/>
                <path d="M22.8121 68.5234C22.237 68.2919 21.9607 67.6412 22.1852 67.0656C22.4163 66.4894 23.0657 66.2053 23.6401 66.4368C24.2152 66.6618 24.4987 67.3125 24.2677 67.8953C24.0956 68.3291 23.67 68.5986 23.2297 68.5986C23.0883 68.5986 22.9535 68.576 22.8121 68.5234Z" fill="white"/>
                <path d="M51.0095 66.4149C50.7187 65.8686 50.9279 65.1879 51.4724 64.8965C52.0176 64.6051 52.6969 64.814 52.9877 65.3603C53.2786 65.9059 53.0701 66.5865 52.5249 66.8787C52.3609 66.9605 52.1743 67.0058 52.0023 67.0058C51.5992 67.0058 51.2114 66.7888 51.0095 66.4149Z" fill="white"/>
                <path d="M15.9169 65.1121C15.2985 64.6993 15.1375 63.8553 15.5583 63.2453C15.9702 62.6257 16.8125 62.4644 17.4213 62.886C18.0397 63.2988 18.2006 64.134 17.7799 64.7528C17.5201 65.1393 17.0994 65.3452 16.6691 65.3452C16.4093 65.3452 16.1495 65.2734 15.9169 65.1121Z" fill="white"/>
                <path d="M57.2794 62.436C56.8763 61.9649 56.9361 61.2543 57.4062 60.8504C57.8763 60.4465 58.5855 60.5064 58.9886 60.9774C59.3844 61.4485 59.3319 62.1592 58.8618 62.563C58.6453 62.7427 58.3917 62.8252 58.13 62.8252C57.8166 62.8252 57.5031 62.6901 57.2794 62.436Z" fill="white"/>
                <path d="M10.1981 59.9608C9.7724 59.5189 9.78771 58.809 10.2433 58.3752C10.6908 57.9487 11.4 57.9713 11.8249 58.4198C12.2506 58.8689 12.228 59.5788 11.7805 60.0054C11.564 60.215 11.2877 60.3121 11.0115 60.3121C10.7134 60.3121 10.4218 60.1996 10.1981 59.9608Z" fill="white"/>
                <path d="M62.5565 57.2008C62.0637 56.8269 61.9668 56.1235 62.34 55.6306C62.7132 55.1368 63.4224 55.0397 63.9144 55.4136C64.3998 55.7876 64.4968 56.4902 64.1236 56.9839C63.9071 57.2753 63.5711 57.4323 63.2358 57.4323C62.9967 57.4323 62.7576 57.3578 62.5565 57.2008Z" fill="white"/>
                <path d="M5.54854 53.8653C5.2271 53.3343 5.39912 52.6463 5.92901 52.325C6.45891 52.0029 7.14551 52.1753 7.46622 52.7062C7.78766 53.2299 7.61564 53.9252 7.08574 54.2465C6.90644 54.3517 6.70527 54.4036 6.51139 54.4036C6.13018 54.4036 5.76501 54.2166 5.54854 53.8653Z" fill="white"/>
                <path d="M66.5945 50.9563C66.0267 50.6941 65.7883 50.0288 66.0493 49.4679C66.3029 48.9069 66.975 48.6601 67.5347 48.9216C68.0945 49.1837 68.3409 49.8491 68.0792 50.41C67.8853 50.8212 67.4823 51.0608 67.0646 51.0608C66.8999 51.0608 66.7432 51.0308 66.5945 50.9563Z" fill="white"/>
                <path d="M2.31668 46.9029C2.12207 46.3193 2.4282 45.6759 3.01786 45.4816C3.60753 45.2793 4.24238 45.5941 4.43627 46.1849C4.63744 46.7678 4.32402 47.4112 3.73435 47.6055C3.61482 47.6427 3.49601 47.6654 3.37647 47.6654C2.91363 47.6654 2.47339 47.374 2.31668 46.9029Z" fill="white"/>
                <path d="M69.1915 43.9859C68.5872 43.8515 68.206 43.2526 68.3329 42.6544C68.4677 42.049 69.0647 41.6597 69.6689 41.7948C70.2739 41.9292 70.6543 42.5274 70.5202 43.1335C70.408 43.6565 69.9451 44.0158 69.4305 44.0158C69.3482 44.0158 69.2738 44.0085 69.1915 43.9859Z" fill="white"/>
                <path d="M0.445001 39.4333C0.354912 38.6971 0.892825 38.024 1.62666 37.9433C2.37012 37.8715 3.03311 38.4008 3.11358 39.137C3.19405 39.882 2.65701 40.5463 1.9223 40.6269C1.87769 40.6269 1.82434 40.6357 1.77885 40.6357C1.09837 40.6357 0.516722 40.1248 0.445001 39.4333Z" fill="white"/>
                <path d="M68.8779 35.5193C68.8779 35.4834 68.8779 35.4562 68.8779 35.429C68.8779 35.4027 68.8779 35.3756 68.8779 35.3484C68.8779 34.6034 69.4779 34.0022 70.2214 34.0022C70.9561 33.9934 71.5649 34.5947 71.5649 35.3396C71.5649 35.3756 71.5649 35.4027 71.5649 35.429C71.5649 35.4562 71.5649 35.4834 71.5649 35.5193C71.5649 36.2546 70.9648 36.8655 70.2214 36.8655C69.4779 36.8655 68.8779 36.2546 68.8779 35.5193Z" fill="white"/>
                <path d="M1.64465 32.9693C1.03239 32.9021 0.584861 32.3492 0.651917 31.7357C0.711685 31.1222 1.26418 30.6738 1.87644 30.7337C2.49526 30.8009 2.94352 31.3545 2.87646 31.9672C2.81669 32.5508 2.32397 32.9773 1.76419 32.9773C1.72702 32.9773 1.68183 32.9693 1.64465 32.9693Z" fill="white"/>
                <path d="M68.3037 28.2205C68.1689 27.6151 68.5493 27.0162 69.1543 26.8818C69.7585 26.7401 70.3562 27.1213 70.4903 27.7268C70.6245 28.333 70.244 28.9311 69.639 29.0728C69.5574 29.0882 69.475 29.0955 69.4007 29.0955C68.8854 29.0955 68.4225 28.7442 68.3037 28.2205Z" fill="white"/>
                <path d="M3.06934 25.3823C2.62709 25.2366 2.39203 24.7601 2.53744 24.3224C2.68285 23.8793 3.15899 23.6437 3.60124 23.7894C4.04349 23.9357 4.27855 24.4122 4.13314 24.8554C4.01561 25.2087 3.68488 25.4272 3.3383 25.4272C3.24865 25.4272 3.15899 25.4163 3.06934 25.3823Z" fill="white"/>
                <path d="M65.9895 21.4151C65.7286 20.8542 65.9669 20.1881 66.5267 19.9194C67.0865 19.6572 67.7585 19.904 68.0194 20.4649C68.2811 21.0258 68.042 21.6919 67.475 21.9534C67.3255 22.0279 67.1615 22.0578 67.0048 22.0578C66.5865 22.0578 66.1761 21.819 65.9895 21.4151Z" fill="white"/>
                <path d="M5.86196 18.7976C5.33206 18.4755 5.16005 17.7875 5.48075 17.2565C5.7949 16.7255 6.48151 16.5539 7.01869 16.8753C7.54859 17.1893 7.71987 17.8773 7.39916 18.4083C7.18998 18.7596 6.81679 18.9546 6.43631 18.9546C6.24243 18.9546 6.04053 18.902 5.86196 18.7976Z" fill="white"/>
                <path d="M62.0876 15.4029C61.6311 14.8201 61.7474 13.9673 62.3299 13.5186C62.9212 13.0699 63.7626 13.1777 64.2192 13.7701C64.667 14.3529 64.5603 15.2057 63.969 15.6544C63.7267 15.8428 63.4311 15.9331 63.1538 15.9331C62.7506 15.9331 62.3474 15.7535 62.0876 15.4029Z" fill="white"/>
                <path d="M9.99988 12.8877C9.46197 12.3759 9.43573 11.5231 9.94566 10.985C10.4565 10.446 11.2979 10.4197 11.8445 10.9307C12.3824 11.4425 12.4087 12.2865 11.8988 12.8334C11.6294 13.1121 11.2716 13.2558 10.9218 13.2558C10.5912 13.2558 10.2597 13.1296 9.99988 12.8877Z" fill="white"/>
                <path d="M57.3093 10.0699C56.8318 9.66597 56.7794 8.95534 57.1752 8.48427C57.5782 8.01319 58.2794 7.9533 58.7495 8.34915C59.2277 8.75304 59.2874 9.45636 58.8844 9.93474C58.6679 10.1969 58.3465 10.3313 58.033 10.3313C57.7721 10.3313 57.5185 10.2415 57.3093 10.0699Z" fill="white"/>
                <path d="M15.6392 7.71375C15.2886 7.19739 15.4154 6.5021 15.93 6.1508C16.438 5.7995 17.1392 5.93388 17.4825 6.44951C17.8338 6.95857 17.699 7.65386 17.1917 8.00516C16.9978 8.14027 16.7813 8.20016 16.5576 8.20016C16.2062 8.20016 15.8556 8.0278 15.6392 7.71375Z" fill="white"/>
                <path d="M51.3528 6.04563C50.8084 5.75422 50.5992 5.07353 50.8827 4.52796C51.1743 3.98166 51.8536 3.77205 52.3981 4.06419C52.9433 4.3483 53.1597 5.02898 52.8682 5.57455C52.667 5.95653 52.2785 6.17344 51.8755 6.17344C51.7042 6.17344 51.5249 6.13546 51.3528 6.04563Z" fill="white"/>
                <path d="M22.0577 3.99701C21.8339 3.42076 22.1102 2.76272 22.6852 2.5385C23.2596 2.30625 23.909 2.58305 24.1401 3.1593C24.3719 3.73481 24.0883 4.38556 23.5213 4.61781H23.5133C23.3791 4.66966 23.237 4.69961 23.1029 4.69961C22.6554 4.6996 22.237 4.43084 22.0577 3.99701Z" fill="white"/>
                <path d="M44.6217 3.62265C43.9054 3.42458 43.4838 2.6805 43.6815 1.97147C43.8783 1.25281 44.613 0.831249 45.3293 1.02844C45.3293 1.02844 45.3293 1.02844 45.3293 1.01968C46.0457 1.21687 46.4664 1.96183 46.2696 2.6805C46.1087 3.27208 45.5707 3.66734 44.9803 3.66734C44.8631 3.66734 44.7381 3.64894 44.6217 3.62265Z" fill="white"/>
                <path d="M29.1409 1.75337C29.0367 1.13988 29.4544 0.564361 30.0593 0.466494C30.6709 0.362054 31.246 0.773241 31.3502 1.38674C31.4471 2.00023 31.0368 2.57575 30.4252 2.67288C30.3655 2.68822 30.3057 2.68822 30.2459 2.68822C29.7007 2.68822 29.2306 2.29894 29.1409 1.75337Z" fill="white"/>
                <path d="M37.5974 2.26899C36.9779 2.23904 36.5005 1.71538 36.5303 1.09532C36.5602 0.474519 37.0901 -0.00459005 37.7097 0.0326575C38.3212 0.0626023 38.7993 0.586263 38.7695 1.20706C38.7396 1.80522 38.2469 2.26899 37.6499 2.26899C37.6346 2.26899 37.6127 2.26899 37.5974 2.26899Z" fill="white"/>
                <path d="M36.7096 61.0074C36.6572 60.3866 37.12 59.8483 37.7395 59.8038C38.3591 59.7512 38.8963 60.215 38.9407 60.8358C38.9859 61.4566 38.5231 61.9948 37.9035 62.0394C37.881 62.0394 37.8511 62.0394 37.8212 62.0394C37.2395 62.0394 36.7468 61.5909 36.7096 61.0074Z" fill="white"/>
                <path d="M30.0141 61.4566C29.4172 61.3215 29.0367 60.7233 29.1708 60.1178C29.3057 59.5116 29.9099 59.1377 30.5142 59.2728C31.1118 59.4072 31.4923 60.0054 31.3582 60.6108C31.2387 61.1345 30.7758 61.4865 30.2605 61.4865C30.1862 61.4865 30.0965 61.4785 30.0141 61.4566Z" fill="white"/>
                <path d="M43.9656 59.7208C43.6874 59.0302 44.0277 58.2493 44.7178 57.9802C45.407 57.7015 46.1863 58.0425 46.4645 58.734C46.733 59.4246 46.3927 60.2055 45.7026 60.4842C45.5417 60.5464 45.3808 60.5736 45.2102 60.5736C44.6732 60.5736 44.1711 60.2502 43.9656 59.7208Z" fill="white"/>
                <path d="M22.6473 58.5695C22.1102 58.2627 21.9309 57.5748 22.237 57.0365C22.5431 56.5055 23.2297 56.3186 23.7669 56.6253C24.2968 56.9313 24.4834 57.6193 24.178 58.1583C23.9688 58.5169 23.5956 58.7192 23.2071 58.7192C23.0132 58.7192 22.8267 58.6739 22.6473 58.5695Z" fill="white"/>
                <path d="M51.1163 55.948C50.8249 55.5838 50.8867 55.0563 51.2448 54.7649C51.6088 54.4729 52.1353 54.5348 52.4261 54.8996C52.7169 55.2584 52.6557 55.7914 52.2971 56.0773C52.1407 56.2005 51.956 56.2624 51.7712 56.2624C51.5246 56.2624 51.2786 56.1556 51.1163 55.948Z" fill="white"/>
                <path d="M16.4526 53.6338C16.0349 53.1773 16.0575 52.4667 16.5124 52.0482C16.9679 51.629 17.6691 51.6516 18.0948 52.1081C18.5124 52.5565 18.4905 53.2671 18.035 53.6929C17.8258 53.8879 17.5503 53.9924 17.274 53.9924C16.9752 53.9924 16.6764 53.8726 16.4526 53.6338Z" fill="white"/>
                <path d="M56.2939 50.7766C55.7793 50.4326 55.6525 49.7293 55.9958 49.221H56.0031C56.3464 48.7046 57.0483 48.5776 57.5556 48.9296C58.0629 49.2736 58.1978 49.9762 57.8465 50.4852C57.63 50.8066 57.2794 50.9782 56.9215 50.9782C56.705 50.9782 56.4885 50.911 56.2939 50.7766Z" fill="white"/>
                <path d="M11.9816 47.0898C11.7134 46.5362 11.9445 45.8628 12.5042 45.5941C13.0567 45.3246 13.7288 45.5561 13.997 46.117C14.2659 46.6706 14.0342 47.344 13.4824 47.6128C13.3257 47.688 13.1537 47.7252 12.9897 47.7252C12.5793 47.7252 12.1762 47.4937 11.9816 47.0898Z" fill="white"/>
                <path d="M59.8844 44.083C59.2947 43.9033 58.966 43.2752 59.1453 42.6844C59.3246 42.0862 59.9515 41.7568 60.5484 41.9365C61.1381 42.1235 61.4668 42.7516 61.2875 43.3424C61.1381 43.8215 60.6905 44.1356 60.2124 44.1356C60.1082 44.1356 59.9959 44.1202 59.8844 44.083Z" fill="white"/>
                <path d="M9.63829 39.5212C9.54863 38.9077 9.96701 38.3322 10.5785 38.2424C11.1908 38.1526 11.7579 38.571 11.8548 39.1845C11.9445 39.798 11.5268 40.3662 10.9146 40.4634C10.8548 40.4707 10.803 40.4707 10.7433 40.4707C10.1981 40.4707 9.72065 40.0741 9.63829 39.5212Z" fill="white"/>
                <path d="M60.2204 35.505C60.2204 35.4897 60.2204 35.4751 60.2204 35.4678C60.2204 35.4451 60.2204 35.4225 60.2204 35.3999C60.2124 34.7798 60.7124 34.2707 61.332 34.2707C61.9515 34.2707 62.4515 34.7718 62.4595 35.3926C62.4595 35.4079 62.4595 35.4298 62.4515 35.4524C62.4515 35.4678 62.4595 35.4824 62.4595 35.505C62.4595 36.1178 61.9515 36.6269 61.34 36.6269C60.7204 36.6269 60.2204 36.1178 60.2204 35.505Z" fill="white"/>
                <path d="M10.564 32.8648C9.9517 32.775 9.53405 32.2068 9.62371 31.5933C9.71336 30.9805 10.2804 30.554 10.892 30.6511C11.5042 30.741 11.9299 31.3092 11.8402 31.9227C11.7579 32.4763 11.2805 32.8801 10.728 32.8801C10.6755 32.8801 10.6237 32.8721 10.564 32.8648Z" fill="white"/>
                <path d="M59.1154 28.2205C58.9288 27.6297 59.2576 27.0016 59.8472 26.8219C60.4369 26.6349 61.071 26.9643 61.2503 27.5552C61.4369 28.146 61.1082 28.7741 60.5185 28.9611C60.407 28.9983 60.2948 29.013 60.1825 29.013C59.7051 29.013 59.2648 28.7069 59.1154 28.2205Z" fill="white"/>
                <path d="M12.4598 25.5058C11.9073 25.2363 11.6682 24.5636 11.9372 24.01C12.1981 23.4491 12.8701 23.2169 13.4299 23.4864C13.9897 23.7486 14.2208 24.4212 13.9525 24.9821C13.7586 25.3787 13.3629 25.6183 12.9445 25.6183C12.7805 25.6183 12.6165 25.5803 12.4598 25.5058Z" fill="white"/>
                <path d="M55.9433 21.6992C55.5927 21.1829 55.7196 20.4876 56.2269 20.1363C56.7422 19.7843 57.4361 19.9113 57.7867 20.4277C58.138 20.936 58.0112 21.632 57.5031 21.9833C57.3093 22.1177 57.0855 22.1849 56.869 22.1849C56.5104 22.1849 56.1598 22.0133 55.9433 21.6992Z" fill="white"/>
                <path d="M16.2936 19.2012C15.747 18.699 15.7111 17.8463 16.2219 17.2985C16.724 16.7516 17.5741 16.7157 18.1208 17.2179C18.6578 17.7297 18.6937 18.5825 18.1925 19.1206C17.924 19.4081 17.5654 19.5606 17.2068 19.5606C16.8762 19.5606 16.5534 19.4352 16.2936 19.2012Z" fill="white"/>
                <path d="M50.8563 16.5639C50.2738 16.1064 50.1758 15.2632 50.6324 14.6795C51.0977 14.0959 51.94 13.9977 52.5225 14.4552C53.1042 14.9214 53.203 15.7654 52.7368 16.3491C52.477 16.6804 52.0834 16.8601 51.689 16.8601C51.3933 16.8601 51.0977 16.7619 50.8563 16.5639Z" fill="white"/>
                <path d="M22.1473 14.0181C21.8339 13.4872 22.0132 12.7992 22.5504 12.4851C23.0883 12.1784 23.7669 12.3581 24.0811 12.8963C24.3945 13.4273 24.2152 14.1153 23.678 14.4293C23.4987 14.5272 23.3048 14.579 23.1182 14.579C22.7297 14.579 22.3565 14.3775 22.1473 14.0181Z" fill="white"/>
                <path d="M44.7106 12.7765C44.1355 12.5523 43.8446 11.9016 44.0684 11.3261C44.2922 10.7498 44.9416 10.4657 45.5167 10.6899C46.0991 10.9149 46.3826 11.5656 46.1581 12.1411C45.9868 12.5823 45.5612 12.8591 45.1136 12.8591C44.9795 12.8591 44.8374 12.8291 44.7106 12.7765Z" fill="white"/>
                <path d="M29.0739 10.9149C28.9325 10.3087 29.3057 9.70323 29.9099 9.56812C30.5142 9.42643 31.1118 9.80767 31.2532 10.4058C31.3954 11.012 31.0142 11.6175 30.4099 11.7526C30.3276 11.7745 30.2459 11.7825 30.1636 11.7825C29.6563 11.7825 29.1934 11.4305 29.0739 10.9149Z" fill="white"/>
                <path d="M37.6561 10.9127H37.6621C37.1974 10.8842 36.8503 10.4805 36.8837 10.0149C36.9115 9.54928 37.315 9.20145 37.7796 9.23541C38.2383 9.26883 38.5909 9.67308 38.5575 10.1326C38.5242 10.5758 38.1546 10.9181 37.7179 10.9181C37.7009 10.9181 37.6785 10.9181 37.6561 10.9127Z" fill="white"/>
                <path d="M36.5768 52.127C36.4876 51.3917 37.0072 50.7186 37.7506 50.6283C38.4853 50.539 39.1475 51.0683 39.2375 51.8045C39.3268 52.5398 38.8072 53.2129 38.0638 53.3032C38.0638 53.3032 38.0638 53.3032 38.0734 53.3032C38.0191 53.3119 37.9562 53.3119 37.9028 53.3119C37.2311 53.3119 36.6582 52.8098 36.5768 52.127Z" fill="white"/>
                <path d="M29.649 52.0701C29.0666 51.8532 28.775 51.2032 28.9995 50.6269C29.216 50.0434 29.8574 49.752 30.4398 49.9689V49.9762C31.0142 50.1931 31.313 50.8365 31.0892 51.4201C30.9172 51.8685 30.4923 52.1453 30.044 52.1453C29.9099 52.1453 29.7758 52.1227 29.649 52.0701Z" fill="white"/>
                <path d="M44.3899 49.7892C44.0385 49.2736 44.1654 48.5776 44.6734 48.2263C45.1807 47.875 45.8746 48.0021 46.2332 48.5104C46.5838 49.0187 46.457 49.7147 45.9497 50.0733C45.7478 50.2084 45.5313 50.2683 45.3075 50.2683C44.9569 50.2683 44.6063 50.1033 44.3899 49.7892Z" fill="white"/>
                <path d="M22.6853 47.2615C22.2742 46.7977 22.3121 46.0871 22.7822 45.6759C23.237 45.2647 23.9462 45.3092 24.3566 45.773C24.7677 46.2368 24.7298 46.9474 24.2677 47.3586C24.0512 47.5456 23.7895 47.6427 23.5213 47.6427C23.2151 47.6427 22.909 47.5156 22.6853 47.2615Z" fill="white"/>
                <path d="M50.0394 44.1955C49.4949 43.9114 49.2785 43.2307 49.5693 42.6844C49.8528 42.1308 50.5321 41.9292 51.0773 42.2133C51.6291 42.4974 51.8383 43.1781 51.5548 43.7317C51.5548 43.7317 51.5548 43.7317 51.5475 43.7244C51.3456 44.1129 50.9651 44.3298 50.562 44.3298C50.3827 44.3299 50.2041 44.2853 50.0394 44.1955Z" fill="white"/>
                <path d="M18.527 39.8216C18.3477 39.1029 18.7868 38.3676 19.5031 38.1967C20.2291 38.0171 20.9542 38.457 21.1335 39.1748C21.3128 39.8935 20.8737 40.6288 20.1477 40.8085C20.0401 40.8356 19.9334 40.8444 19.8259 40.8444C19.2258 40.8444 18.6792 40.4316 18.527 39.8216Z" fill="white"/>
                <path d="M51.1071 35.5118C51.1071 35.503 51.1071 35.503 51.1071 35.503C51.1071 35.4758 51.1071 35.4574 51.1071 35.4311C51.1071 34.6862 51.7071 34.0849 52.4505 34.0849C53.1844 34.0849 53.794 34.6765 53.794 35.4215C53.794 35.4399 53.794 35.4487 53.794 35.4671C53.794 35.4846 53.794 35.4934 53.794 35.5118C53.794 36.2471 53.194 36.8579 52.4505 36.8579C51.7071 36.8579 51.1071 36.2471 51.1071 35.5118Z" fill="white"/>
                <path d="M19.5503 32.6552C18.9454 32.5062 18.5802 31.9 18.7289 31.3019C18.7289 31.3019 18.7289 31.3019 18.7216 31.3019C18.871 30.6957 19.4753 30.3298 20.0802 30.4715C20.6772 30.6212 21.0504 31.2274 20.9009 31.8255C20.7741 32.3412 20.3193 32.6851 19.8113 32.6851C19.7216 32.6851 19.64 32.6778 19.5503 32.6552Z" fill="white"/>
                <path d="M49.5394 28.2651C49.2486 27.7195 49.4578 27.0388 50.0022 26.7474C50.5547 26.4553 51.226 26.6649 51.5176 27.2112C51.8084 27.764 51.5992 28.4374 51.0547 28.7288C50.8907 28.8187 50.7114 28.864 50.5321 28.864C50.1291 28.864 49.7413 28.647 49.5394 28.2651Z" fill="white"/>
                <path d="M22.737 25.3714C22.2742 24.9595 22.2297 24.2496 22.6401 23.7931C22.6401 23.7931 22.6401 23.7931 22.6401 23.7858C23.0504 23.3221 23.7596 23.2768 24.2225 23.6887C24.6853 24.0999 24.7298 24.8105 24.3194 25.2736C24.0956 25.5204 23.7895 25.6555 23.4834 25.6555C23.2151 25.6555 22.9535 25.5584 22.737 25.3714Z" fill="white"/>
                <path d="M44.6209 22.7458C44.1136 22.3945 43.9788 21.6919 44.3301 21.1829C44.6807 20.6745 45.3819 20.5402 45.8899 20.8915C46.3972 21.2428 46.5313 21.938 46.1807 22.4544C45.9642 22.7685 45.6136 22.9408 45.255 22.9408C45.0386 22.9408 44.8148 22.8729 44.6209 22.7458Z" fill="white"/>
                <path d="M28.9398 20.405C28.716 19.8295 29.0068 19.1788 29.5812 18.9546C30.1636 18.7377 30.813 19.0291 31.0295 19.6053C31.2532 20.1808 30.9624 20.8316 30.3873 21.0485V21.0558C30.2532 21.1011 30.1191 21.123 29.9843 21.123C29.5367 21.123 29.1111 20.8542 28.9398 20.405Z" fill="white"/>
                <path d="M37.6916 20.3666C36.9481 20.2763 36.4198 19.6128 36.5091 18.8679C36.5991 18.1317 37.2613 17.6023 38.0047 17.6917C38.7394 17.782 39.2677 18.4455 39.1785 19.1816C39.098 19.8731 38.5155 20.3753 37.8438 20.3753C37.7904 20.3753 37.7449 20.3753 37.6916 20.3666Z" fill="white"/>
                <path d="M31.5149 43.1335C30.977 42.8268 30.7904 42.1388 31.1038 41.5998C31.41 41.0615 32.0966 40.8819 32.6345 41.1886C33.1717 41.5027 33.351 42.1834 33.0448 42.7216C32.8356 43.081 32.4625 43.2825 32.0747 43.2825C31.8801 43.2825 31.6862 43.238 31.5149 43.1335ZM38.7695 42.7143C38.456 42.1834 38.6426 41.4954 39.1725 41.1813C39.7097 40.8746 40.3963 41.0542 40.7025 41.5925C41.0159 42.1235 40.8293 42.8115 40.2994 43.1255C40.1201 43.2227 39.9262 43.2752 39.7396 43.2752C39.3518 43.2752 38.9706 43.0737 38.7695 42.7143ZM27.111 35.5196C27.111 34.8988 27.611 34.3978 28.2306 34.3978C28.8428 34.3905 29.3501 34.8915 29.3501 35.5196C29.3501 36.1331 28.8501 36.6415 28.2306 36.6415C27.611 36.6415 27.111 36.1404 27.111 35.5196ZM42.7278 35.5228C42.7224 35.5113 42.7224 35.4949 42.7224 35.4779C42.7224 35.0123 43.0974 34.6311 43.562 34.6311C44.0267 34.6311 44.4017 35.0068 44.4017 35.4724C44.4017 35.4779 44.4017 35.4834 44.4017 35.4889C44.4017 35.4889 44.4017 35.4949 44.4017 35.5004C44.4017 35.5004 44.4017 35.5004 44.4072 35.5004C44.4072 35.96 44.0267 36.3417 43.5675 36.3417C43.1083 36.3417 42.7393 35.9769 42.7278 35.5228ZM31.3166 29.283C31.0876 28.8787 31.2215 28.3627 31.6189 28.1327C32.0223 27.8971 32.5373 28.0319 32.7724 28.4356C33.002 28.8338 32.8675 29.3503 32.4701 29.5859C32.3356 29.6642 32.1902 29.6976 32.0448 29.6976C31.7589 29.6976 31.4735 29.5519 31.3166 29.283ZM39.1499 29.8061C38.6128 29.4994 38.4262 28.8114 38.7396 28.2731C39.0457 27.7341 39.725 27.5552 40.2622 27.8619C40.7994 28.1679 40.986 28.8486 40.6799 29.3869C40.4714 29.7535 40.0902 29.9558 39.7024 29.9558C39.5158 29.9558 39.3292 29.9106 39.1499 29.8061Z" fill="white"/>
        </svg>
    )
}