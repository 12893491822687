import { ROTATION_TYPES } from './constants/rotationsTypes';

export const rotations = {
    [ROTATION_TYPES.accManagement]: {
        name: 'Аккаунт-менеджмент',
        text: 'Аккаунт-менеджеры отвечают за взаимодействие \n' +
            'с клиентами компании, \n' +
            'то есть с ритейлерами, приобретающими продукцию PepsiCo для реализации конечному потребителю. Именно аккаунт-менеджер несет ответственность \n' +
            'за продажи компании.'
    },
    [ROTATION_TYPES.brandMarketing]: {
        name: 'Бренд-маркетинг',
        text: 'Бренд-менеджер PepsiCo отвечает за продвижение и позиционирование всех продуктов бренда, от запуска новых продуктов до продвижения  в точках продаж'
    },
    [ROTATION_TYPES.electronicCommerce]: {
        name: 'Электронная коммерция',
        text: 'Электронная коммерция — это не только финансовые или торговые транзакции, но и цепочки глобальных бизнес-процессов, связанных с проведением транзакций.'
    },
    [ROTATION_TYPES.insights]: {
        name: 'Инсайты',
        text: 'Потребительский инсайт — инструмент для маркетологов и отдела продаж, часть стратегии продвижения товара/услуги, позволяющий выделить продукт компании на фоне конкурентов и добиться успеха. Инсайт лежит в основе любого бренда и продажи, на него нанизываются остальные стратегии.',
    },
    [ROTATION_TYPES.revenueManagement]: {
        name: 'Управление доходами',
        text: 'Управление доходами — это применение аналитики, которая рассматривает и прогнозирует поведение потребителей и оптимизирует портфель продуктов для максимального роста прибыли.'
    },
    [ROTATION_TYPES.category]: {
        name: 'Категорийный менеджмент',
        text: 'Категорийные менеджеры несут ответственность за анализ данных о продажах и выработку рекомендаций по оптимальному ассортименту, ценообразованию и выкладке в магазинах, чтобы увеличить продажи не только одного продукта, но и всей занимаемой компанией доли рынка в определенной категории продукции.'
    },
    [ROTATION_TYPES.businessDevelopment]: {
        name: 'Развитие бизнеса',
        text: 'Представители команды «Развитие бизнеса» отвечают за повышение операционной эффективности продаж. На этой ротации тебе предстоит работать с аналитикой каналов продаж, маршрутов и ИТ инструментов торговых представителей.'
    },
};