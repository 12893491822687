import { anBorisuk, diBerganova, inShalneva, vlRastorguev, vlTiranov } from './constants/images';

export const mentors = [
    {
        id: '0',
        name: 'Владимир Тиранов',
        isWoman: false,
        position: 'Вице-президент по продажам\nв современной торговле',
        text: 'Привет, я Владимир! Я знаю все\nо трендах современной торговли, потому что большинство из них мы формируем в PepsiCo. Выбирай меня — будь на волне карьерного развития!',
        image: vlTiranov
    },
    {
        id: '1',
        name: 'Диана Берганова',
        isWoman: true,
        position: 'Старший коммерческий\nдиректор в категории снеки',
        text: 'Здравствуй, на связи Диана!\nВыбирай меня, и мы построим\nтвою карьеру со вкусом!\nКаким? Решим вместе ;)',
        image: diBerganova,
    },
    {
        id: '2',
        name: 'Инна Шальнева',
        isWoman: true,
        position: 'Старший директор по развитию бизнеса в каналах продаж',
        text: 'Привет! Я  Инна, и я стану\nакселератором твоих даже\nсамых смелых идей и помогу\nпостроить лучший карьерный\nтрек в PepsiCo!',
        image: inShalneva
    },
    {
        id: '3',
        name: 'Владимир Расторгуев',
        isWoman: false,
        position: 'Старший директор\nнаправления E-commerce',
        text: 'Привет! Я научу тебя\nпродвигать не только бренды\nв Интернете, но и свою карьеру. Присоединяйся ко мне,\nчтобы выстроить уникальную\nкарьерную стратегию ;)',
        image: vlRastorguev
    },
    {
        id: '4',
        name: 'Антон Борисюк',
        isWoman: false,
        position: 'Старший директор по исследованиям\nи цифровой трансформации ',
        text: 'Привет! Я  Антон, и я помогу\nтебе исследовать твои\nсильные стороны и произвести\nкарьерную трансформацию \nот новичка до профи ;)',
        image: anBorisuk
    },
];
