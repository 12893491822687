import React from 'react';

export const Logo = (props) => {
    return (
        <svg {...props} viewBox="0 0 102 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M26.8068 17.4153H29.1078C30.0056 17.4153 30.347 17.7216 30.347 18.4718C30.347 19.1942 30.0055 19.5001 29.1078 19.5001H26.8068V17.4153ZM23.242 25.0032H26.807V21.9177H30.6254C33.1535 21.9177 34.0638 20.4453 34.0638 18.5416C34.0638 16.3319 33.1278 15.0814 30.5997 15.0814H23.242V25.0032Z"
                fill="white"/>
            <path
                d="M35.2394 15.0814H44.8341V17.4988H38.8044V18.8329H44.4049V21.2513H38.8044V22.5853H44.8341V25.0032H35.2394V15.0814Z"
                fill="white"/>
            <path
                d="M49.8912 17.4153H52.192C53.0897 17.4153 53.4312 17.7216 53.4312 18.4718C53.4312 19.1942 53.0897 19.5001 52.192 19.5001H49.8912V17.4153ZM46.3262 25.0032H49.8912V21.9177H53.7089C56.2377 21.9177 57.1481 20.4453 57.1481 18.5416C57.1481 16.3319 56.2122 15.0814 53.6835 15.0814H46.3262V25.0032Z"
                fill="white"/>
            <path
                d="M60.5988 20.7926C58.8545 20.5003 58.1344 19.3886 58.1344 17.9994C58.1344 15.4282 60.4226 14.734 63.3549 14.734C67.3745 14.734 69.0309 15.8869 69.1575 17.9856H65.0107C65.0107 17.5825 64.7957 17.3318 64.4676 17.193C64.1515 17.0406 63.7472 16.9851 63.3549 16.9851C62.293 16.9851 61.9267 17.2765 61.9267 17.6938C61.9267 17.9712 62.04 18.1525 62.394 18.2079L66.6542 18.9024C68.4496 19.1942 69.5622 20.1113 69.5622 21.7924C69.5622 24.2111 67.767 25.3506 63.7593 25.3506C61.0162 25.3506 58.0199 24.9338 58.0074 22.0155H62.3056C62.3177 22.3486 62.432 22.5709 62.6852 22.7239C62.9499 22.863 63.3421 22.9327 63.8981 22.9327C65.0104 22.9327 65.3143 22.5989 65.3143 22.1263C65.3143 21.8345 65.1496 21.5429 64.6702 21.4599L60.5988 20.7926Z"
                fill="white"/>
            <path d="M70.7248 15.0814H74.2895V25.0032H70.7248V15.0814Z" fill="white"/>
            <path
                d="M87.5012 21.14C87.2736 22.4463 86.8685 23.5024 85.9835 24.2248C85.1118 24.9475 83.746 25.3506 81.5721 25.3506C79.4104 25.3506 75.4028 25.1834 75.4028 20.0425C75.4028 14.9006 79.4104 14.7338 81.5721 14.7338C83.7206 14.7338 87.0331 15.0392 87.5012 18.9724H83.4811C83.3799 18.263 82.9494 17.2349 81.5721 17.2349C80.1175 17.2349 79.1949 18.0269 79.1949 20.0424C79.1949 22.0573 80.0926 22.8489 81.3947 22.8489C82.5201 22.8489 83.1901 22.3348 83.4809 21.1398H87.5012V21.14Z"
                fill="white"/>
            <path
                d="M92.1657 20.0427C92.1657 18.027 93.0889 17.2352 94.5429 17.2352C95.9964 17.2352 96.9198 18.0272 96.9198 20.0427C96.9198 22.0577 95.9964 22.8492 94.5429 22.8492C93.0889 22.849 92.1657 22.0575 92.1657 20.0427ZM88.3728 20.0427C88.3728 25.1834 92.3803 25.3508 94.5427 25.3508C96.7046 25.3508 100.712 25.1836 100.712 20.0427C100.712 14.9008 96.7046 14.734 94.5427 14.734C92.3803 14.734 88.3728 14.9006 88.3728 20.0427Z"
                fill="white"/>
            <path
                d="M17.8553 15.1798C18.0855 10.4196 13.9334 5.05896 8.2729 4.39635L8.28257 4.3313C13.8629 4.3313 18.1409 9.43891 18.1409 13.4683C18.1355 14.3071 18.0685 14.8269 17.949 15.2042L17.8553 15.1798ZM17.5171 15.7848C17.1459 16.2333 16.6789 16.6849 16.1319 17.1136C15.1254 11.3909 10.872 6.38674 7.86484 4.92613L7.80043 4.97052C10.8247 7.42444 14.4318 12.1904 15.8345 17.3381C15.1634 17.8271 14.3877 18.2772 13.5307 18.6425C10.6769 15.4249 8.01628 9.21952 7.1589 5.28176L7.07169 5.30771C7.09103 9.13005 9.83588 15.6189 13.1832 18.7851C12.4403 19.0748 11.6415 19.3007 10.8026 19.4354C7.59389 18.1787 5.30994 14.1535 5.30994 10.0302C5.30994 7.3384 6.14005 5.67325 6.30064 5.33247L6.23226 5.30566C6.03799 5.59625 4.82193 7.26054 4.82193 10.1666C4.82193 14.8274 7.14681 18.3899 10.2334 19.5117C9.17743 19.6253 8.06619 19.591 6.92611 19.3582L6.90315 19.4293C7.23332 19.5495 8.35354 20.0144 10.0624 20.0144C13.9277 20.0144 16.5406 17.64 17.5924 15.8352L17.5171 15.7848Z"
                fill="white"/>
            <path
                d="M9.67597 2.64941C6.74583 2.64941 3.31006 4.74019 3.31006 6.65136C3.31006 7.39063 3.99407 8.01517 5.40975 8.01517C8.69615 8.01517 11.7494 5.78285 11.7494 4.04702C11.7494 3.13361 10.8622 2.64941 9.67597 2.64941ZM10.8457 3.99307C10.8457 5.45624 8.00385 7.24483 5.6282 7.24483C4.57861 7.24483 4.01151 6.83644 4.01151 6.16256C4.01151 4.67839 6.8857 2.92019 9.11405 2.92019C10.5669 2.92019 10.8457 3.6511 10.8457 3.99307Z"
                fill="white"/>
            <path
                d="M2.14269 7.53627C2.06032 7.67712 1.73048 8.31566 1.73048 8.9699C1.73048 10.1833 2.97055 11.3098 5.25139 11.3098C9.63918 11.3098 14.7086 8.11659 14.7086 5.15251C14.7086 3.822 13.4347 3.09861 12.5502 2.94905L12.5336 3.00095C12.8087 3.087 13.8165 3.5502 13.8165 4.73542C13.8165 7.09715 9.3406 10.3151 4.88218 10.3151C3.17535 10.3151 2.10003 9.4821 2.10003 8.26871C2.10003 7.8849 2.18085 7.63205 2.20226 7.55881L2.14269 7.53627Z"
                fill="white"/>
            <path
                d="M16.5176 5.37413C16.638 5.46445 17.3607 6.05996 17.3607 7.18611C17.3607 11.0259 11.2756 14.8016 5.89585 14.8016C2.68768 14.8016 1.2252 13.2355 1.28996 11.8449H1.34746C1.47318 12.5671 2.51482 13.8593 5.32271 13.8593C10.7091 13.8593 16.7233 9.95247 16.7233 6.52947C16.7233 5.94233 16.562 5.57645 16.4708 5.41374L16.5176 5.37413Z"
                fill="white"/>
            <path
                d="M18.5515 8.9588C18.5767 9.04058 18.7078 9.40817 18.7078 9.98387C18.7078 14.2552 12.5496 17.912 7.19378 17.912C4.43718 17.912 2.99767 16.5881 2.69374 16.0505L2.74278 16.0146C3.50554 16.6742 5.00791 17.1753 6.7612 17.1753C11.4914 17.1753 18.5496 13.6172 18.4866 8.97434L18.5515 8.9588Z"
                fill="white"/>
        </svg>

    );
};